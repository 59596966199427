var app = {
    data: {
        pageIsScrolling: false,
        scrollDuration: 250,
        prevTop: 0
    },

    elements: {
        plansTabs: document.querySelectorAll('.pricing-tab'),
        plansContent: document.querySelectorAll('.pricing-content'),
        youtubeOverlay: document.querySelector('.yt-overlay'),
        youtubePlaceholder: document.querySelector('.yt-placeholder')
    },

    yt: {
        playerReady: false,
        player: null,

        init: function() {
            var tag = document.createElement('script');
            tag.src = "https://www.youtube.com/iframe_api";

            var firstScriptTag = document.getElementsByTagName('script')[0];
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

            this.initEvents();
        },

        initEvents: function() {
            //Implement story "Read more" f-ty
            $('a.show-more-story').click(function(event)
            {
                $('p.show-more-story').show();
                $(this).hide();

                event.preventDefault();
                return false;
            })
        },

        apiReady: function() {
            app.yt.player = new YT.Player('video', {
                height: '390',
                width: '640',
                videoId: '17lPsYlEUNo',
                playerVars: {
                    autoplay: 0,
                    controls: 1,
                    showinfo: 0
                },
                events: {
                    'onReady': function() {
                        app.yt.playerReady = true;
                    },

                    'onStateChange': app.yt.onPlayerStateChange
                }
            });
        },

        onPlayerStateChange: function(stateInfo) {
            if (stateInfo.data == 2) {
                app.elements.youtubePlaceholder.style.display = 'block';
                app.elements.youtubePlaceholder.style.background = 'transparent';
                app.elements.youtubeOverlay.style.display = 'block';
            }
        }
    },

    init: function() {
        window.addEventListener('mousewheel', function() {
            clearTimeout(this.pageIsScrolling);
        }.bind(app));

        window.addEventListener('scroll', function () {
            let top = ((window.pageYOffset || document.scrollTop) - (document.clientTop || 0)) || 0;
            if (this.prevTop > top && top > 200) {
                $('.page-header').addClass('fixed');
            } else {
                $('.page-header').removeClass('fixed');
            }
            this.prevTop= top;
        });

        //Initialize top navigation scrolling
        $('.nav-link').each(function() {
            var pageId = $(this).data('id');

            if (pageId) {
                $(this).click(function(event)
                {
                    var containerElement = $('#' + pageId);
                    var scrollTop = containerElement.offset().top;
                    $('html, body').animate({ scrollTop: scrollTop }, 700);

                    event.preventDefault();
                    return false;
                })
            }
        });

        //moblie nav expanding
        $('.burger').click(function () {
            $(this).toggleClass('active');
            $('nav').toggleClass('active');
            $('.page-header').toggleClass('active')
        });

        $('.nav-link').click(function () {
            $('nav').toggleClass('active');
            $('.burger').toggleClass('active');
        });

        this.elements.plansTabs.forEach(function(button) {
            button.addEventListener('click', function(event) {
                event.preventDefault();

                var planID = button.getAttribute('data-id');

                this.changePlan(button, planID);
            }.bind(app));
        });

        this.yt.init();

        this.elements.youtubePlaceholder.addEventListener('click', function(event) {
            if (app.yt.playerReady) {
                app.elements.youtubeOverlay.style.display = 'none';
                app.elements.youtubePlaceholder.style.display = 'none';
                app.yt.player.playVideo();
            }
        }.bind(app));
    },

    changePlan: function(button, planID) {
        var newPlan = document.getElementById('plan-' + planID);


        if (newPlan) {
            this.elements.plansContent.forEach(function(plan) {
                plan.style.display = 'none';
            });

            newPlan.style.display = 'block';

            this.elements.plansTabs.forEach(function(tab) {
                tab.className = tab.className.replace(/\bactive\b/, '');
            });

            button.className += ' active';
        }
    }
}

app.init();

function onYouTubeIframeAPIReady() {
    app.yt.apiReady();
}